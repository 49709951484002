import React from "react";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "../ui/select";

import { IDict } from "../constants";
import { cn } from "@/lib/utils";

interface IControlledCustomSelect {
  data: string[];
  onChange: (value: string, name: string) => void;
  dict: IDict;
  placeholder: string;
  value: string | undefined;
  name: string;
  darkBg?: boolean;
  disabled?: boolean;
  title: string;
}

export const ControlledCustomSelect = ({
  data,
  value,
  onChange,
  dict,
  placeholder,
  name,
  darkBg,
  disabled,
  title,
}: IControlledCustomSelect) => {
  return (
    <Select onValueChange={(_value) => onChange(_value, name)} value={value || ""} disabled={disabled}>
      <SelectTrigger
        title={title}
        className={cn(
          "w-full font-saira text-16 font-[500] leading-[1.75] tracking-[-0.1px]",
          darkBg ? "text-white bg-[#FFFFFF0D]" : ""
        )}
      >
        <SelectValue
          placeholder={dict[placeholder]}
          className="font-saira text-16 font-[500] leading-[1.75] tracking-[-0.1px]"
        />
      </SelectTrigger>
      <SelectContent className="data-[side=bottom]:-translate-y-8 w-[96%] mx-auto data-[side=top]:translate-y-8">
        <SelectGroup className="flex flex-col gap-2">
          <SelectLabel>{dict[placeholder]}</SelectLabel>
          {data.map((item: string) => (
            <SelectItem
              key={item}
              value={item}
              className="font-saira text-16 font-[400] leading-[1.75] tracking-[-0.1px]"
            >
              {dict[item]}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
